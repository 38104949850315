<template>
  <v-menu
    v-model="dateMenu"
    class="app-date-picker-dual"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template #activator="{on}">
      <v-text-field
        ref="dateRange"
        class="w-100"
        :class="[{'icon-rotate': dateMenu}, classes]"
        :value="dateRangeDisplay"
        readonly
        outlined
        dense
        :disabled="disabled"
        :label="label"
        :hint="hint"
        :placeholder="placeholder"
        :rules="rules"
        :validate-on-blur="false"
        clearable
        hide-details
        v-bind="dateRangeAttrs"
        @click:append="dateMenu = !dateMenu"
        v-on="on"
        @input="checkCleared"
        @click:clear="date = ''"
      />
    </template>

    <v-date-picker
      v-model="date"
      :min="allowPastDates ? '' : new Date().toISOString()"
      :max="allowFutureDates ? '' : new Date().toISOString()"
      :first-day-of-week="1"
      :disabled="disabled"
      no-title
      scrollable
      color="primary"
      actions
      range
      @input="pickerInput"
      @change="handleChange"
    >
      <v-spacer></v-spacer>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AppDatePickerDual',

  props: {
    rules: {
      type: Array,
      default: undefined,
    },

    value: {
      type: Object,
      default: undefined,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: undefined,
    },

    hint: {
      type: String,
      default: undefined,
    },

    placeholder: {
      type: String,
      default: undefined,
    },

    allowPastDates: {
      type: Boolean,
      default: true,
    },

    allowFutureDates: {
      type: Boolean,
      default: true,
    },

    isFilter: {
      type: Boolean,
      default: false,
    },

    classes: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dateMenu: undefined,
      dateModel: {
        dateStart: undefined,
        dateEnd: undefined,
      },
    };
  },

  computed: {
    dateRangeAttrs() {
      return this.isFilter
        ? {
            appendIcon: 'mdi-chevron-down',
            prependInnerIcon: 'mdi-calendar',
            singleLine: true,
          }
        : {
            appendIcon: 'mdi-calendar',
          };
    },

    dateRangeDisplay() {
      switch (this.date.length) {
        case 0:
          return '';
        case 1:
          return `${moment(this.dateStart).format('DD.MM.YYYY')}`;
        case 2: {
          const s = moment(this.dateStart).format('DD.MM.YYYY');
          const e = moment(this.dateEnd).format('DD.MM.YYYY');
          return `${s} - ${e}`;
        }
      }
      return '';
    },

    dateStart: {
      get() {
        return this.dateModel.dateStart;
      },
      set(value) {
        this.dateModel.dateStart = value;
      },
    },

    dateEnd: {
      get() {
        return this.dateModel.dateEnd;
      },
      set(value) {
        this.dateModel.dateEnd = value;
      },
    },

    date: {
      get() {
        const res = [];
        this.dateStart && res.push(moment(this.dateStart).format('YYYY-MM-DD'));
        this.dateEnd && res.push(moment(this.dateEnd).format('YYYY-MM-DD'));
        return res;
      },

      set(val) {
        switch (val.length) {
          case 0: {
            this.dateStart = undefined;
            this.dateEnd = undefined;
            this.$emit('input', this.dateModel);
            break;
          }
          case 1: {
            this.dateStart = val[0];
            this.dateEnd = undefined;
            break;
          }
          case 2: {
            this.dateStart = val[0];
            this.dateEnd = val[1];
            this.$emit('input', this.dateModel);
            break;
          }
        }
      },
    },
  },

  watch: {
    dateMenu(val) {
      if (!val) {
        this.handleClose();
      }
    },
    value(val) {
      this.checkCleared(val);
    },
  },

  mounted() {
    this.init(this.value);
  },

  methods: {
    handleClose() {
      const {dateStart, dateEnd} = this;
      if (!dateStart && !dateEnd) return;
      if (dateStart && dateEnd) return;

      if (!dateEnd) {
        const now = new Date();
        const ds = new Date(dateStart);

        if (now < ds) {
          this.dateEnd = dateStart;
          this.dateStart = moment(now).format('YYYY-MM-DD');
        } else {
          this.dateEnd = moment(now).format('YYYY-MM-DD');
        }
      }
      this.$emit('input', this.dateModel);
      this.handleChange();
    },

    handleChange() {
      this.$emit('change');
    },

    checkCleared(val) {
      if (!val || (!val.dateStart && !val.dateEnd)) {
        this.date = [undefined, undefined];
      }
    },

    pickerInput(newDate) {
      if (newDate.length === 2) {
        if (new Date(newDate[0]) > new Date(newDate[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      }
    },

    init(val) {
      if (val) {
        this.dateModel = {
          dateStart: val.dateStart,
          dateEnd: val.dateEnd,
        };
      } else {
        this.dateModel = {
          dateStart: undefined,
          dateEnd: undefined,
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.app-date-picker-dual {
  z-index: 100;
}
.icon-rotate::v-deep .v-input__append-inner .v-icon.mdi-chevron-down {
  transform: rotate(180deg);
}
</style>
