import dashboardApi from '@/api/dashboard';
import {mutationTypes} from '@/store/modules/dashboard/mutations';

export const actionsTypes = {
  getDashboardByUser: '[dashboard] get dashboard by user',
  getDashboardByCompany: '[dashboard] get dashboard by company',
  changeDashboard: '[dashboard] change dashboard',

  getUser: '[dashboard] get user',
  getRoadmaps: '[dashboard] get roadmaps',
  getRoadmapsSummary: '[dashboard] get roadmaps summary',
  getSpecsWithGrades360: '[dashboard] get specs with grades 360',
  getHistoryGrades360: '[dashboard] get history grades 360',
  getDiagram360BySpec: '[dashboard] get diagram 360 by spec',
  getTests: '[dashboard] get tests',
  getTargets: '[dashboard] get targets',

  getPotential: '[dashboard] get potential',
  getConflictness: '[dashboard] get conflictness',
  getManagementStyle: '[dashboard] get management style',
  getTeamRoles: '[dashboard] get team roles',
};

const actions = {
  [actionsTypes.getDashboardByUser](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getDashboardByUserStart);
      dashboardApi
        .getDashboardByUser(userId)
        .then((response) => {
          context.commit(mutationTypes.getDashboardByUserSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getDashboardByUserFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getDashboardByCompany](context, companyId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getDashboardByCompanyStart);
      dashboardApi
        .getDashboardByCompany(companyId)
        .then((response) => {
          context.commit(mutationTypes.getDashboardByCompanySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getDashboardByCompanyFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.changeDashboard](context, {companyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeDashboardStart);
      dashboardApi
        .changeDashboard(companyId, data)
        .then((response) => {
          context.commit(mutationTypes.changeDashboardSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeDashboardFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getUser](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUserStart);
      dashboardApi
        .getUser(userId)
        .then((response) => {
          context.commit(mutationTypes.getUserSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUserFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getRoadmaps](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getRoadmapsStart);
      dashboardApi
        .getRoadmaps(userId)
        .then((response) => {
          context.commit(mutationTypes.getRoadmapsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getRoadmapsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getRoadmapsSummary](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getRoadmapsSummaryStart);
      dashboardApi
        .getRoadmapsSummary(userId)
        .then((response) => {
          context.commit(mutationTypes.getRoadmapsSummarySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getRoadmapsSummaryFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getSpecsWithGrades360](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSpecsWithGrades360Start);
      dashboardApi
        .getSpecsWithGrades360(userId)
        .then((response) => {
          context.commit(mutationTypes.getSpecsWithGrades360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSpecsWithGrades360Failure);
          reject(result);
        });
    });
  },
  [actionsTypes.getDiagram360BySpec](context, {userId, specCode}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getDiagram360BySpecStart);
      dashboardApi
        .getDiagram360BySpec(userId, specCode)
        .then((response) => {
          context.commit(mutationTypes.getDiagram360BySpecSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getDiagram360BySpecFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getHistoryGrades360](context, {userId, specCode, dateStart, dateEnd}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getHistoryGrades360Start);
      dashboardApi
        .getHistoryGrades360(userId, specCode, dateStart, dateEnd)
        .then((response) => {
          context.commit(mutationTypes.getHistoryGrades360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getHistoryGrades360Failure);
          reject(result);
        });
    });
  },
  [actionsTypes.getTests](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTestsStart);
      dashboardApi
        .getTests(userId)
        .then((response) => {
          context.commit(mutationTypes.getTestsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTestsFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getTargets](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTargetsStart);
      dashboardApi
        .getTargets(userId)
        .then((response) => {
          context.commit(mutationTypes.getTargetsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTargetsFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getPotential](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getPotentialStart);
      dashboardApi
        .getPotential(userId)
        .then((response) => {
          context.commit(mutationTypes.getPotentialSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getPotentialFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getConflictness](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getConflictnessStart);
      dashboardApi
        .getConflictness(userId)
        .then((response) => {
          context.commit(mutationTypes.getConflictnessSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getConflictnessFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getManagementStyle](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getManagementStyleStart);
      dashboardApi
        .getManagementStyle(userId)
        .then((response) => {
          context.commit(mutationTypes.getManagementStyleSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getManagementStyleFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getTeamRoles](context, userId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTeamRolesStart);
      dashboardApi
        .getTeamRoles(userId)
        .then((response) => {
          context.commit(mutationTypes.getTeamRolesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTeamRolesFailure);
          reject(result);
        });
    });
  },
};

export default actions;
