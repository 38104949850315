export default {
  userMetadata: {
    firstName: 'Имя',
    lastName: 'Фамилия',
    middleName: 'Отчество',
    fullName: 'ФИО',
    city: 'Город',
    address: 'Адрес',
    TIN: 'ИНН',
    phone: 'Телефон',
    password: 'Пароль',
    email: 'Эл.почта',
    messengers: 'Мессенджеры',
    website: 'Сайт',
    specialization: 'Специализация',
    teams: 'Команды',
    level: 'Уровень',
    salary: 'Заработная плата',
    startOfWorkCompany: 'Начало работы в компании',
    position: 'Должность',
    startOfWorkPosition: 'Начало работы в должности',
    birthdate: 'Дата рождения',
    familyStatus: 'Семейное положение',
    children: 'Дети',
    education: 'Образование',
    specialty: 'Специальность',
  },
  familyStatuses: {
    widower: 'Вдовец (вдова)',
    neverMarried: 'Никогда не состоял(а) в браке',
    divorced: 'Разведен(а) официально (развод зарегистрирован)',
    brokeUp: 'Разошелся(лась)',
    married: 'Состоит в зарегистрированном браке',
    unregisteredMarriage: 'Состоит в незарегистрированном браке',
  },
  myProfile: 'Мой профиль',
  myProgress: 'Мой прогресс',
  myTasks: 'Мне назначено',
  myMeetings: 'Мои встречи',
  myLevel: 'Мой текущий уровень',
  myCompanies: 'Мои компании',
  myResume: 'Моё резюме',
  curatedByMe: 'Я курирую',
  administration: 'Конструктор профилей',
  administrator: 'Администратор',
  progress: 'Прогресс',
  profile: 'Профиль',
  taskBoard: 'Доска задач',
  taskBoards: 'Планы развития',
  company: 'Компания',
  companies: 'Компании',
  companyProfile: 'Профиль компании',
  feedbackFeed: 'Лента фидбэка',
  companyFeed: {
    title: 'Лента',
    settings: 'Настройки ленты',
    settingsHint: 'Отметьте вопросы, которые необходимо скрыть из ленты',
    placeholder:
      'В ленте пока ничего нет. Для появления комментариев, дождитесь, когда организатор назначит опрос.',
  },
  orgStructure: 'Орг. структура',
  licensing: 'Лицензирование',
  modules: 'Модули',
  modulesMetadata: {
    adaptation: {
      title: 'Адаптация',
      description:
        'Автоматизация онбординга, адаптации и ввода в должность через чат-бот в Телеграм.<br/>Конструктор адаптационных треков и готовые шаблоны.<br/>Отслеживание прогресса на всём пути адаптации сотрудника.',
    },
    evaluation: {
      title: 'Оценка и развитие',
      description:
        'Проведение регулярных аттестаций или точечных оценок сотрудников<br/>Конструктор профилей с готовыми шаблонами.<br/>Оценка 360/180 с гибкими настройками и автоматическим ИПР по результатам.<br/>Сотрудники могут проходить опросы в интерфейсе платформы, а также через Beehive-bot в Телеграм.<br/>Индивидуальные и сводные отчёты.',
    },
    targets: {
      title: 'Цели',
      description:
        'Функционал по постановке и трекингу целей сотрудников.<br/>Поможет оценивать результативность команды в режиме реального времени.<br/>Модуль позволяет ставить задачи и цели на разные периоды времени и приоритизировать их, собирать итоги и видеть отчёт по всей команде, по подразделению или по конкретному человеку. С его помощью вы сможете определять цели на развитие или испытательный срок и хранить всю историю эффективности ваших сотрудников.',
    },
    meetings: {
      title: 'Встречи',
      description:
        'Планирование и проведение встреч 1 на 1.<br/>Отслеживание встреч при помощи календаря.<br/>Фиксация результатов и задач по итогам встреч.',
    },
    testsAndSurveys: {
      title: 'Тесты и опросы',
      description:
        'Конструктор опросов и тестов для проведения HR исследований и оценки знаний сотрудников.<br/>Готовые шаблоны исследований с лучшими HR-практиками.<br/>Готовое решение для оценки удовлетворенности, лояльности (eNPS), вовлечённости и выгорания сотрудников.<br/>Формирование культуры вовлечения за счёт регулярного и простого формата проведения.',
    },
    feedback: {
      title: 'Фидбэк (непрерывная обратная связь)',
      description:
        'Непрерывная обратная связь по методу, объединяющему в себе оценку 360 градусов и пульс-опросы сотрудников.<br/>Умные опросы, в которых сотрудники дают обратную связь своим коллегам.<br/>Интеллектуальный подбор взаимодействующих на основе реальных коммуникаций сотрудников.<br/>Доступ к методологии «Эффективность сотрудников», которая содержит вопросы по компетенциям, эффективности и благополучию сотрудников.',
    },
    interconnect: {
      title: 'Аналитика коммуникаций',
      description:
        'Анализ коммуникаций и реального рабочего процесса в компании на основании данных почты, календаря и систем управления задачами.',
    },
    htTests: {
      title: 'Личностное тестирование',
      description:
        'Психологические тесты от наших партнеров Лаборатории Гуманитарные технологии.<br/>Оценка мотивации сотрудника или всей команды, структуры личности и потенциала сотрудника, сборка команды по ролям Белбина.',
    },
  },
  contacts: 'Контакты',
  instructions: 'Инструкции',
  legalInfo: 'Правовая информация',
  addInfo: 'Дополнительная информация',
  aboutCompany: 'О компании',
  jobInfo: 'Сведения о работе',
  changelog: 'Обновления ПО',
  aboutMe: 'Обо мне',
  employees: 'Сотрудники',
  employeeStatuses: {
    current: 'Действующие',
    dismissed: 'Уволенные',
    invited: 'Приглашённые',
  },
  team: 'Команда',
  teams: 'Команды',
  withoutTeam: 'Без команды',
  leader: 'Лидер',
  surveys: 'Опросы',
  survey: 'Опрос',
  result: 'Результат',
  surveyResults: 'Результаты опроса',
  periodicSurvey: 'Периодический опрос',
  recipients: 'Получатели',
  periodLimit: 'Период прохождения теста',
  periodExpired: 'Установленный период истёк',
  surveysTypes: {
    engagement: 'Вовлеченность и удовлетворенность',
    burnout: 'Выгорание',
    corporateCulture: 'Корпоративная культура',
  },
  surveysMetadata: {
    surveys: {
      title: 'Опросы',
      periodic: 'Периодический опрос',
      nonperiodic: 'Непериодический опрос',
      report: 'Сводка',
      details: 'По отдельным пользователям',
      emplStatistic: 'Сотрудников прошли опрос',
      allQuestions: 'Все',
      createButton: 'Создать новый опрос',
      surveyDone: 'Опрос пройден',
      surveyPlaceholder: 'Пока нет назначенных или незавершенных опросов',
      table: {
        headers: {
          name: 'Название опроса',
          lastSend: 'Посл. отправка',
          nextSend: 'След. отправка',
          dateStart: 'Дата старта',
          dateEnd: 'Дата окончания',
          period: 'Период',
          count: 'Статистика',
          status: 'Статус',
        },
      },
      statuses: {
        draft: 'Черновик',
        published: 'Опубликован',
      },
      changeButton: 'Редактировать опрос',
      menu: {
        sendToParticipants: 'Назначить участников',
        sendNow: 'Оправить опрос сейчас',
        makeUnavailable: 'Скрыть из доступа',
        makeAvailable: 'Сделать доступным',
        exportResults: 'Выгрузить результаты',
        copyLink: 'Скопировать ссылку',
        copyEntity: 'Скопировать опрос',
        copyEntityTo: 'Скопировать в другую компанию',
        del: 'Удалить опрос',
      },
      successMessages: {
        changeStatus: 'Доступность опроса успешно изменена',
        copyEntity: 'Копирование опроса успешно завершено',
        sendNow: 'Опрос отправлен участникам',
      },
      errorMessages: {
        changeStatus: 'Не удалось изменить доступность опроса',
        copyEntity: 'Не удалось скопировать опрос',
        sendNow: 'Не удалось отправить опрос',
        copyEntityNotSelected: 'Не выбрана ни одна компания',
      },
    },
    tests: {
      title: 'Тесты',
      unregistered: 'Незарегистрированный пользователь',
      validAnswers: 'Правильных ответов',
      periodic: 'Периодический тест',
      nonperiodic: 'Непериодический тест',
      createButton: 'Создать новый тест',
      testDone: 'Тест пройден',
      testPlaceholder: 'Пока нет назначенных тестов',
      table: {
        headers: {
          name: 'Название теста',
          lastSend: 'Посл. отправка',
          nextSend: 'След. отправка',
          dateStart: 'Дата старта',
          dateEnd: 'Дата окончания',
          period: 'Период',
          count: 'Кол-во прошедших тест',
          availability: 'Разрешено проходить гостям',
          status: 'Статус',
        },
      },
      statuses: {
        draft: 'Черновик',
        published: 'Опубликован',
      },
      changeButton: 'Редактировать тест',
      menu: {
        sendToParticipants: 'Назначить участников',
        sendNow: 'Оправить тест сейчас',
        makeUnavailable: 'Скрыть из доступа',
        makeAvailable: 'Сделать доступным',
        exportResults: 'Выгрузить результаты',
        copyLink: 'Скопировать ссылку',
        copyEntity: 'Скопировать тест',
        copyEntityTo: 'Скопировать в другую компанию',
        del: 'Удалить тест',
      },
      successMessages: {
        changeStatus: 'Доступность теста успешно изменена',
        copyEntity: 'Копирование теста успешно завершено',
        sendNow: 'Тест отправлен участникам',
      },
      errorMessages: {
        changeStatus: 'Не удалось изменить доступность теста',
        copyEntity: 'Не удалось скопировать тест',
        sendNow: 'Не удалось отправить тест',
        copyEntityNotSelected: 'Не выбрана ни одна компания',
      },
    },
    availability: {
      availabilityTooltip:
        'Разрешено проходить неавторизованным пользователям. Результаты прохождения будут обезличены.',
      availabilityGuestTooltip:
        'Результаты прохождения опроса/теста не будут отображать сведения о пользователе.',
      availabilityNotGuestTooltip:
        'Результаты прохождения опроса/теста отображают сведения обо всех пользователях, если пользователь не авторизован, то его ответы будут помечены как ответы гостя.',
    },
    cycle: 'Цикл',
    questions: {
      notFound: 'Тип вопроса не распознан',
      default: '- Выберите тип вопроса -',
      boolean: 'Да или Нет',
      multi_choice: 'Мультивыбор',
      number: 'Число',
      single_choice: 'Единичный выбор',
      text: 'Короткий текст',
      date: 'Дата',
      textarea: 'Длинный текст',
      file: 'Файл',
      scale: 'Шкала',
      phone: 'Телефон',
      time: 'Время',
    },
    questionFields: {
      units: 'Единицы измерения',
      minMax: 'Мин/макс значение',
      validAnswer: 'Правильный ответ',
      showValidAnswer: 'Указать правильный ответ',
      unitsPlaceholder: 'Например: шт, кг, дней',
      from: 'От',
      to: 'До',
      decimals: 'Разрешить десятичные',
      answerVariants: 'Варианты ответа',
      scaleMaxLength: 'Максимальная длина названий: 30 символов',
      scaleMaxLengthHint:
        'Шкала будет отображаться в горизонтальном положении, в полях «Начало и конец шкалы» вы можете указать текстовые или числовые значения, с ограничением в 30 символов',
      answerVariantsPlaceholder: 'Введите название варианта ответа',
      addVariant: 'Добавить ещё вариант',
      delVariant: 'Удалить вариант',
      pointCount: 'Количество точек',
      startsWith: 'Начало со значения «1»',
      scaleBegin: 'Начало шкалы',
      scaleBeginPh: 'Введите название первой точки',
      scaleEnd: 'Конец шкалы',
      scaleEndPh: 'Введите название последней точки',
      maxLength: 'Ограничение длины',
      addFile: 'Прикрепить файл',
      addedFile: 'Прикреплённый файл',
      surveyTextQuestionLabel: 'Ответ',
      showInFeed: 'Включить отображение вопроса в ленте',
      showInFeedHint: 'Ответ будет опубликован в ленте <strong>без указания вашего имени</strong>',
      question: 'Вопрос',
      questionPlaceholder: 'Введите текст вопроса',
      qType: 'Тип вопроса',
      showRich: 'Включить расширенное описание вопроса',
      richBody: 'Расширенное описание',
      surveySettingsAdditional: 'Оценка результата не включена',
      surveySettingsAdditionalBtn: 'Включить',
      surveySettingsAdditionalLink: 'Перейти к настройке оценки результатов',
      newOption: 'Новый вариант',
      copyQuestion: 'Копировать вопрос',
      copyPrefix: 'Копия',
      editQuestion: 'Редактировать вопрос',
      deleteForever: 'Удалить без возможности восстановления',
      moveDown: 'Переместить ниже',
      moveUp: 'Переместить выше',
      restore: 'Восстановить',
      finishButtonName: 'Завершить прохождение',
      addQuestion: 'Добавить вопрос',
      deleted: 'Вопрос {num} удалён',
    },
    feedbacks: {
      title: 'Фидбэк',
      report: 'Сводка',
      details: 'По отдельным пользователям',
      summaryOfQuestions: 'Сводная по вопросам',
      emplStatistic: 'Сотрудников прошли опрос',
      allQuestions: 'Все',
      surveyDone: 'Опрос пройден',
      createButton: 'Создать фидбэк',
      changeButton: 'Редактировать фидбэк',
      managingMethodologies: 'Управление методологиями',
      methodology: 'Методология',
      commonMethodology: 'Общая методология',
      listOfQuestions: 'Список вопросов',
      placeholder:
        'Здесь будут отображаться опросы и результаты прохождения. Для начала оценки нажмите кнопку «Создать фидбэк».',
      table: {
        headers: {
          name: 'Название',
          status: 'Статус',
          lastStart: 'Старт цикла',
          nextStart: 'Старт следующего цикла',
          count: 'Статистика',
          cycle: 'Цикл',
        },
      },
      statuses: {
        started: 'Запущен',
        stopped: 'Остановлен',
      },
      menu: {
        copyEntity: 'Скопировать фидбэк',
        deleteEntity: 'Удалить фидбэк',
      },
      infoMessages: {
        start:
          'При запуске начнётся отправка частей опросов в выбранные дни недели. Если опрос ранее был остановлен, то начнётся новый цикл отправки, т.е. опросы начнут приходить сначала. Запланированные отправки частей опросов из предыдущего цикла будут аннулированы.',
        stop: 'Отправки частей опросов из данного цикла будут продолжаться, пока опрос не будет пройден полностью, но новый цикл опросов не будет начат.',
      },
      successMessages: {
        copyEntity: 'Копирование опроса успешно завершено',
        deleteEntity: 'Опрос успешно удалён',
      },
      errorMessages: {
        copyEntity: 'Не удалось скопировать опрос',
        deleteEntity: 'Не удалось удалить опрос',
      },
    },
    researchment: {
      title: 'Исследования',
      titleOne: 'Исследование',
      buttons: {
        started: 'Остановить',
        stopped: 'Запустить',
        paused: 'Продолжить',
      },
      statuses: {
        started: 'Запущено',
        stopped: 'Остановлено',
        paused: 'Пауза',
      },
    },
  },
  dashboardMetadata: {
    titles: {
      competencies360: 'Компетенции (по 360)',
      knowledge: 'Знания',
      personalPotential: 'Личностный потенциал',
      conflictLevel: 'Уровень конфликтности',
      managementStyle: 'Стиль менеджмента (Адизес)',
      teamRoles: 'Роли в команде (Белбин)',
      targets: 'Цели',
      feedbacks: 'Фидбэки',
    },
    managementStyles: {
      unusual: 'Несвойственный стиль',
      lackOfTrends: 'Отсутствие тенденций',
      moderate: 'Умеренное выражение стиля',
      vivid: 'Яркая выраженность стиля',
    },
    teamRoles: {
      preferredRoles: 'Наиболее приемлемые',
      unwantedRoles: 'Наименее приемлемые',
    },
  },
  meetings: 'Встречи',
  meeting: 'Встреча',
  meetingsMetadata: {
    roles: {
      participant: 'Мои встречи',
      supervisor: 'Я запланировал',
    },
    statuses: {
      planned: 'Запланирована',
      done: 'Проведена',
      overdue: 'Просрочена',
      cancelled: 'Отменена',
    },
    formats: {
      online: 'Онлайн',
      offline: 'Оффлайн',
    },
    statusesDescriptions: {
      planned: 'Встреча запланирована, вы можете перенести встречу на другое время и дату',
      done: 'Встреча завершена, вы можете указать итоги, а также добавить задачи себе и сотруднику',
      overdue: 'Срок встречи истёк, вы можете перенести встречу на другое время и дату',
      cancelled: 'Встреча отменена',
    },
    buttons: {
      addAnotherQuestion: 'Добавить ещё вопрос',
      goToMeeting: 'Перейти во встречу',
      viewTasks: 'Посмотреть задачи',
      doneMeeting: 'Завершить встречу',
      cancelMeeting: 'Отменить встречу',
    },
    agenda: 'Повестка',
    myTasks: 'Мои задачи',
    tasksForEmployee: 'Задачи для сотрудника',
    tasksForSupervisor: 'Задачи руководителя',
    meetingRoom: 'Переговорная',
    resultsMeeting: 'Итоги встречи',
    listOfQuestions: 'Список вопросов',
    createMeeting: 'Запланировать встречу',
    role: 'Роль',
  },
  tests: 'Тесты',
  test: 'Тест',
  pulseTest: 'Пульс-опрос',
  scoreType: 'Вид оценки',
  duration: 'Длительность',
  comment: 'Комментарий',
  comments: 'Комментарии',
  autofill: 'Автоподбор',
  status: 'Статус',
  scoreAndDevelopment: 'Оценка и развитие',
  score: 'Оценка',
  score360: 'Оценка 360',
  approvalScore360: 'Утверждение взаимодействующих для оценки 360',
  negativeScore: 'н/о',
  averageScore: 'Средняя оценка',
  testsTypes: {
    tests360: 'Оценка 360',
    self360: 'Самооценка 360',
    approval360: 'Утвердить список',
    pulseTests: 'Пульс-опросы',
    self: 'Самооценка',
    notSelf: 'По другим пользователям',
    selfApproval: 'По себе',
    notSelfApproval: 'По другим пользователям',
    personal: 'Личностные тесты',
    feedback: 'Фидбэк',
  },
  unlimitedLicenses: 'Количество лицензий не ограничено',
  skill: 'Навык',
  skills: 'Навыки',
  skillsTypes: {
    soft: 'Soft skills',
    hard: 'Hard skills',
    motivation: 'Направление развития карьеры и мотивация',
    teamRoles: 'Роли в команде',
  },
  competence: 'Компетенция',
  competencies: 'Компетенции',
  competenceBlock: 'Блок компетенций',
  indicator: 'Индикатор',
  completionStatuses: {
    completed: 'Завершён',
    notCompleted: 'Не завершён',
  },
  approvalStatuses: {
    awaitingCreator: 'На подтверждении у создателя',
    awaitingEvaluated: 'На подтверждении у сотрудника',
    awaitingSupervisor: 'На подтверждении у руководителя',
    pending: 'На подтверждении',
    approved: 'Подтверждён',
    started: 'Запущен',
    rejected: 'Отклонён',
    cancelled: 'Отменён',
  },
  approvalEvaluatedStatuses: {
    pending: 'На подтверждении у оцениваемого',
    approved: 'Подтверждён оцениваемым',
    rejected: 'Отклонён оцениваемым',
  },
  type: 'Тип',
  environment: 'Окружение',
  supervisors: 'Руководители',
  subordinates: 'Подчинённые',
  colleagues: 'Коллеги',
  experts: 'Эксперты',
  expertRoles: {
    evaluated: 'Оцениваемый',
    supervisor: 'Руководитель',
    subordinate: 'Подчинённый',
    colleague: 'Коллега',
  },
  reEvaluationLock: 'Настройка закрытия сеансов',
  report: 'Отчёт',
  reports: 'Отчёты',
  fixedDevPlan: 'Зафиксированный ИПР',
  devPlan: 'План развития',
  devPlans: 'Планы развития',
  materials: 'Материалы',
  commonMaterials: 'Общие материалы',
  roadmapsTypes: {
    roadmap: 'ИПР по самооценке',
    roadmap360: 'ИПР по 360',
  },
  importance: 'Важность',
  importanceTypes: {
    extra: 'Экстренная',
    high: 'Высокая',
    middle: 'Средняя',
    low: 'Низкая',
    noMatter: 'Не имеет значения',
  },
  conflictLevels: {
    high: 'Высокий',
    mid: 'Средний',
    low: 'Низкий',
  },
  target: 'Цель',
  targets: 'Цели',
  targetLevel: 'Целевой уровень',
  targetsMetadata: {
    roles: {
      self: 'Мои',
      curator: 'Я курирую',
    },
    statuses: {
      awaitingReview: 'На согласовании',
      approved: 'Согласована',
      inProgress: 'В работе',
      inReview: 'На оценке руководителя/куратора',
      inReviewAssignee: 'На оценке сотрудника',
      completed: 'Завершена',
      cancelled: 'Отменена',
    },
    buttons: {
      approve: 'Согласовать',
    },
    curator: 'Руководитель/Куратор',
    noCurator: 'не назначен',
  },
  period: 'Период',
  schedule: 'Расписание',
  date: 'Дата',
  deadline: 'Дедлайн',
  version: 'Версия',
  currentVersion: 'Тек. версия',
  mentor: 'Наставник',
  adaptation: 'Адаптация',
  adaptations: 'Адаптации',
  interactionDiagram: 'Диаграмма взаимодействия',
  testing: 'Тестирование',
  plan: 'План',
  creationDate: 'Дата назначения',
  task: 'Задача',
  tasks: 'Задачи',
  portfolio: 'Портфолио',
  services: 'Услуги',
  workExp: 'Опыт работы',
  organizers: 'Организаторы',
  organizer: 'Организатор',
  managers: 'Менеджеры',
  manager: 'Менеджер',
  education: 'Образование',
  teamMembers: 'Состав команды',
  feedbacks: 'Фидбэки',
  massAssignment: 'Массовое назначение',
  name: 'Название',
  educationLevels: {
    top: 'Высшее образование',
    bachelor: 'Высшее образование - бакалавриат',
    magister: 'Высшее образование - специалитет, магистратура',
    topHighest: 'Высшее образование - подготовка кадров высшей квалификации',
    candidate: 'Кандидат наук',
    adjunct: 'Адъюнктура',
    aspirant: 'Аспирантура',
    doctor: 'Докторантура',
    auxKidsAdults: 'Дополнительное образование для детей и взрослых',
    auxProf: 'Дополнительное профессиональное образование',
    infants: 'Дошкольное образование',
    intern: 'Интернатура',
    starterCommon: 'Начальное общее образование',
    starterProf: 'Начальное профессиональное образование',
    unfinishedTop: 'Неполное высшее образование',
    ordinate: 'Ординатура',
    mainCommon: 'Основное общее образование',
    afterUni: 'Послевузовское образование',
    profTraining: 'Профессиональное обучение',
    middleFull: 'Среднее (полное) общее образование',
    middle: 'Среднее общее образование',
    middleSpecial: 'Среднее профессиональное образование',
  },
  specialty: 'Специальность',
  specializations: 'Специализации',
  statistics: 'Статистика',
  analytics: 'Аналитика',
  interconnect: 'Диаграмма взаимодействий',
  settings: 'Настройки',
  demoAccess: 'Демо доступ',
  buttons: {
    share: 'Поделиться',
    export: 'Экспорт',
    exportToExcel: 'Выгрузить в excel',
    exportReport: 'Выгрузить отчёт',
    copy: 'Копировать',
    download: 'Скачать',
    downloadDocument: 'Скачать документ',
    downloadAllFiles: 'Скачать все файлы',
    downloadTemplate: 'Скачать шаблон',
    uploadSpecialization: 'Загрузить специализацию',
    expandAll: 'Развернуть всё',
    collapseAll: 'Свернуть всё',
    loadMore: 'Показать ещё',
    toTop: 'Наверх',
    link: 'Перейти',
    save: 'Сохранить',
    saveChanges: 'Сохранить изменения',
    create: 'Создать',
    fixate: 'Зафиксировать версию',
    generate: 'Сформировать',
    restart: 'Перезапустить',
    refresh: 'Обновить',
    find: 'Найти',
    remind: 'Напомнить',
    edit: 'Редактировать',
    configure: 'Настроить',
    change: 'Изменить',
    add: 'Добавить',
    addAnother: 'Добавить ещё',
    addTask: 'Добавить задачу',
    addLink: 'Добавить ссылку',
    addQuestion: 'Добавить вопрос',
    addQuestions: 'Добавить вопросы',
    addToBoard: 'Добавить на доску',
    addTarget: 'Добавить цель',
    addComment: 'Добавить комментарий',
    editQuestions: 'Редактировать вопросы',
    appoint: 'Назначить',
    disabled: 'Отключено',
    enabled: 'Включено',
    createTests: 'Назначить тесты',
    createScore: 'Назначить оценку',
    sendScore: 'Отправить оценку',
    sendForApproval: 'Отправить на согласование',
    send: 'Отправить',
    evaluate: 'Оценить',
    evaluateAgain: 'Оценить повторно',
    takeTest: 'Пройти тест',
    takeSurvey: 'Пройти опрос',
    takeTestAgain: 'Пройти тест повторно',
    continueTest: 'Продолжить тест',
    devPlan: 'и получить план развития',
    compositionOfParticipants: 'Состав участников',
    approveList: 'Утвердить список',
    createReport: 'Сформировать отчёт',
    createDevPlan: 'Сформировать ИПР',
    checkReport: 'Проверить отчёт',
    checkDevPlan: 'Проверить ИПР',
    createDiagram: 'Построить диаграмму',
    completeTest: 'Завершить тест',
    complete: 'Завершить',
    invite: 'Отправить приглашения',
    accept: 'Принять',
    reject: 'Отклонить',
    approve: 'Подтвердить',
    open: 'Открыть',
    openAll: 'Открыть всё',
    select: 'Выбрать',
    selectAll: 'Выбрать всё',
    selectAllPeople: 'Выбрать всех',
    deselect: 'Снять выбор',
    reset: 'Сбросить',
    cancel: 'Отмена',
    cancelAction: 'Отменить',
    cancelTest: 'Отменить тест',
    recover: 'Восстановить',
    delete: 'Удалить',
    deleteAll: 'Удалить всё',
    confirm: 'Да',
    confirmDelete: 'Да, удалить',
    close: 'Закрыть',
    closeAll: 'Закрыть всё',
    login: 'Войти',
    exit: 'Выйти',
    clear: 'Понятно',
    done: 'Готово',
    continue: 'Далее',
    back: 'Назад',
    goBack: 'Вернуться назад',
    goToDashboard: 'Перейти в профиль',
    goToBoard: 'Перейти на доску',
    moveUp: 'Вверх',
    moveDown: 'Вниз',
    applyFilter: 'Применить фильтр',
    editFilter: 'Настроить фильтр',
    clearFilter: 'Очистить фильтр',
    block: 'Заблокировать',
    unblock: 'Разблокировать',
    action: 'Действия',
    readAll: 'Прочитать всё',
    inDynamics: 'В динамике',
    showReports: 'Показать отчёты',
    hideReports: 'Скрыть отчёты',
    getToWork: 'Взять в работу',
    logging: 'Логирование',
  },
  toggleGroups: {
    participateTypesHint: 'Здесь можно выбрать кому будет доступно прохождение опроса/теста',
    participateTypes: {
      guest: 'Прохождение доступно всем пользователям, авторизованным и неавторизованным',
      authorized: 'Прохождение доступно только авторизованным пользователям',
      assigned:
        'Прохождение доступно только авторизованным пользователям, на которых был назначен данный опрос/тест',
      names: {
        guest: 'Разрешено гостям',
        authorized: 'Только авторизованным',
        assigned: 'Только назначенным',
      },
    },
    participateAnonymousHint:
      'Здесь можно ограничить сбор данных пользователя. Не влияет на доступность прохождения',
    participateAnonymousHintDisabled:
      'Здесь можно ограничить сбор данных пользователя. Не влияет на доступность прохождения. Не будет применена к запущенным опросам/тестам, или если в них уже есть ответы',
    participateAnonymous: {
      anonymousName: 'Анонимный опрос',
      anonymous: 'Результаты прохождения опроса/теста не будут отображать сведения о пользователе',
      notAnonymousName: 'Неанонимный опрос',
      notAnonymous:
        'Результаты прохождения опроса/теста отображают сведения обо всех пользователях, если пользователь не авторизован, то его ответы будут помечены как ответы гостя',
    },
  },
  inputs: {
    file: 'Выберите файл',
    image: 'Выберите изображение',
  },
  loading: 'Загрузка...',
  search: 'Поиск',
  searchByFullName: 'Поиск по ФИО',
  searchByTitle: 'Поиск по названию',
  searchByTeam: 'Поиск по команде',
  searchBySpec: 'Поиск по специализации',
  searchByQuestion: 'Поиск по вопросу',
  link: 'Ссылка',
  file: 'Файл',
  tags: 'Тэги',
  description: 'Описание',
  info: {
    copyText: 'Текст скопирован',
    copyLink: 'Ссылка скопирована',
  },
  socialNetworks: {
    behance: 'Behance',
    facebook: 'Facebook',
    github: 'GitHub',
    instagram: 'Instagram',
    linkedin: 'LinkedIn',
    ok: 'OK',
    stackoverflow: 'Stack Overflow',
    telegram: 'Telegram',
    twitter: 'Twitter',
    vk: 'VK',
    skype: 'Skype',
  },
  stubs: {
    noItems: 'Вы пока не добавили ни одного элемента',
    noInfo: 'Нет информации',
    noData: 'Отсутствуют данные',
    noTitle: 'Без названия',
    nothingFound: 'Ничего не найдено',
    notSpecified: 'Не указано',
    noDescription: 'Нет описания',
    cityNotSpecified: 'Город не указан',
    companyNotSelected: 'Компания не выбрана',
    notCompleted: 'Не завершён',
    notSelected: 'Не выбрано',
    notAssigned: 'Не назначено',
    noSpecs: 'Нет специализаций',
    noVersions: 'Нет версий',
    noComment: 'Без комментария',
    noComments: 'Без комментариев',
    noQuestions: 'Нет вопросов',
    noTheme: 'Без тематики',
    withoutTeam: 'Без команды',
    teamSpecsNotSelected:
      'Командные специализации не выбраны. Вы можете выбрать их нажав на кнопку «Редактировать» возле имени пользователя или на вкладке «Состав команды»',
    noSkills: 'Отсутствуют навыки для оценки',
    noCompanies: 'Нет компаний',
    noEmployees: 'Нет сотрудников',
    noSubEmployees: 'Нет подчинённых сотрудников',
    noTests: 'Не было назначено ни одного тестирования',
    noTeams: 'Для запуска оценки создайте хотя бы одну команду и добавьте в нее сотрудников',
    noScore: 'Не было назначено ни одной оценки',
    noResults: 'Нет результатов',
    noMethodology:
      'У вас ещё нет ни одной методологии. Чтобы создать методологию, нажмите на кнопку «Создать методологию»',
    noTemplate:
      'У вас ещё нет ни одного шаблона. Чтобы создать шаблон, создайте исследование и сохраните его как шаблон',
  },
  placeholders: {
    specifyNumber: 'Укажите число',
    select: 'Выберите из списка',
    statisticInterval: 'Данные за последние 60 дней',
    teamsSelect: 'По всей компании',
    selectCompany: 'Выберите компанию',
    all: 'Все',
  },
  tooltips: {
    employees:
      'Показывает количество сотрудников на текущую дату. Не зависит от выбранного периода',
    adaptation:
      'Количество сотрудников хотя бы с одним не завершенным планом адаптации. Не зависит от выбранного периода',
    score360:
      'Статистика по запущенным оценкам 360 за выбранный период. Карты развития учитываются только зафиксированные',
    pulseTests:
      'Активность сотрудников по отправленным опросам, которые являются периодической рассылкой. В статистике не учитываются опросы, отправленные по ссылке',
    personalTests:
      'Статистика по психологическим тестам. Блок не зависит от фильтров по команде и дате. Для пополнения лицензий обратитесь в техническую поддержку',
    testing:
      'Статистика по запущенным тестам. В статистике не учитываются тесты, отправленные по ссылке',
    remindTests:
      'Для отправки напоминаний по тесту, установите чекбокс и нажмите на кнопку «Напомнить»',
    targets:
      'Статистика по поставленным целям отображает общее количество целей и среднюю оценку эффективности по всем сотрудникам компании. Вывод статистики по блоку цели не зависит от выбранного периода',
    support: 'Написать в техподдержку',
  },
  attention: 'Внимание',
  uploaded: 'Загружено',
  string: 'Строка',
  error: 'Ошибка',
  errorMessages: {
    default: 'Не удалось загрузить данные',
    permissionDenied: 'Недостаточно прав',
    exportReport: 'Не удалось выгрузить отчёт',
    personalTests: 'Тесты «Мотивация и роли в команде» временно не доступны',
    specializations:
      'Создайте специализации для компании {company_id}, иначе страница команды будет недоступна',
  },
  yes: 'Да',
  no: 'Нет',
  of: 'из',
  by: 'по',
  year: 'год',
  month: 'месяц',
  day: 'день',
  from: 'с',
  to: 'по',
  atTime: 'в {time}',
  shorthands: {
    quarter: 'кв',
    date: {
      year: 'г.',
      months: 'мес.',
    },
    currency: {
      rub: 'руб.',
    },
    months: [
      'янв',
      'фев',
      'март',
      'апр',
      'май',
      'июнь',
      'июль',
      'авг',
      'сент',
      'окт',
      'нояб',
      'дек',
    ],
    days: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
  },
  workDays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт'],
  months: [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
  ],
  quantity: {
    targets: ['цель', 'цели', 'целей'],
    scores: ['балл', 'балла', 'баллов'],
    requiredHint: ['обязательный', 'обязательных', 'обязательных'],
    email: ['письмо', 'письма', 'писем'],
    evaluation: ['оценка', 'оценки', 'оценок'],
    years: ['год', 'года', 'лет'],
    months: ['месяц', 'месяца', 'месяцев'],
    days: ['день', 'дня', 'дней'],
    numerals: ['-ый', '-ой', '-ий', '-ый'],
    team: ['команда', 'команды', 'команд'],
    task: ['задача', 'задачи', 'задач'],
    people: ['человек', 'человека', 'человек'],
    survey: ['опрос', 'опроса', 'опросов'],
    answer: ['ответ', 'ответа', 'ответов'],
    license: ['лицензия', 'лицензии', 'лицензий'],
    specialization: ['специализация', 'специализации', 'специализаций'],
    employee: ['сотрудник', 'сотрудника', 'сотрудников'],
    user: ['пользователь', 'пользователя', 'пользователей'],
    test: ['тест', 'теста', 'тестов'],
    material: ['материал', 'материала', 'материалов'],
    question: ['вопрос', 'вопроса', 'вопросов'],
    element: ['элемент', 'элемента', 'элементов'],
    minute: ['минута', 'минуты', 'минут'],
    selected: {
      masculine: ['Выбран', 'Выбрано', 'Выбрано'],
      feminine: ['Выбрана', 'Выбраны', 'Выбрано'],
    },
    available: {
      feminine: ['Доступна', 'Доступны', 'Доступно'],
    },
  },
  validators: {
    required: 'Обязательное поле',
    invalidValue: 'Некорректное значение',
    positiveInt: 'Введите целое положительное число',
    positiveIntWithZero: 'Введите целое положительное число или ноль',
    phone: 'Некорректный номер телефона',
    email: 'Некорректный e-mail',
    minLength: 'Минимум {minLimit} символов',
    minValue: 'Минимальное значение: {minLimit}',
    maxValue: 'Максимальное значение: {maxLimit}',
    maxLength: 'Длина не должна превышать',
    url: 'Некорректный URL',
    file: 'Размер загружаемого файла не должен превышать',
    unique: 'Поля не должны совпадать',
    notMatch: 'Значения не должны совпадать',
    megabytes: 'МБ',
    numeric: 'Допустимы только цифры',
    dtStartEnd: 'Дата начала должна быть не меньше даты завершения',
    denyPastDates: 'Дата должна быть не меньше текущей',
    denyFutureDates: 'Дата должна быть не больше текущей',
  },
};
