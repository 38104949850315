export const mutationTypes = {
  getDashboardByUserStart: '[dashboard] get dashboard by user start',
  getDashboardByUserSuccess: '[dashboard] get dashboard by user success',
  getDashboardByUserFailure: '[dashboard] get dashboard by user failure',

  getDashboardByCompanyStart: '[dashboard] get dashboard by company start',
  getDashboardByCompanySuccess: '[dashboard] get dashboard by company success',
  getDashboardByCompanyFailure: '[dashboard] get dashboard by company failure',

  changeDashboardStart: '[dashboard] change dashboard start',
  changeDashboardSuccess: '[dashboard] change dashboard success',
  changeDashboardFailure: '[dashboard] change dashboard failure',

  getUserStart: '[dashboard] get user start',
  getUserSuccess: '[dashboard] get user success',
  getUserFailure: '[dashboard] get user failure',

  getRoadmapsStart: '[dashboard] get roadmaps start',
  getRoadmapsSuccess: '[dashboard] get roadmaps success',
  getRoadmapsFailure: '[dashboard] get roadmaps failure',

  getRoadmapsSummaryStart: '[dashboard] get roadmaps summary start',
  getRoadmapsSummarySuccess: '[dashboard] get roadmaps summary success',
  getRoadmapsSummaryFailure: '[dashboard] get roadmaps summary failure',

  getSpecsWithGrades360Start: '[dashboard] get specs with grades 360 start',
  getSpecsWithGrades360Success: '[dashboard] get specs with grades 360 success',
  getSpecsWithGrades360Failure: '[dashboard] get specs with grades 360 failure',

  getHistoryGrades360Start: '[dashboard] get history grades 360 start',
  getHistoryGrades360Success: '[dashboard] get history grades 360 success',
  getHistoryGrades360Failure: '[dashboard] get history grades 360 failure',

  getDiagram360BySpecStart: '[dashboard] get diagram 360 by spec start',
  getDiagram360BySpecSuccess: '[dashboard] get diagram 360 by spec success',
  getDiagram360BySpecFailure: '[dashboard] get diagram 360 by spec failure',

  getTestsStart: '[dashboard] get tests start',
  getTestsSuccess: '[dashboard] get tests success',
  getTestsFailure: '[dashboard] get tests failure',

  getTargetsStart: '[dashboard] get targets start',
  getTargetsSuccess: '[dashboard] get targets success',
  getTargetsFailure: '[dashboard] get targets failure',

  getPotentialStart: '[dashboard] get potential start',
  getPotentialSuccess: '[dashboard] get potential success',
  getPotentialFailure: '[dashboard] get potential failure',

  getManagementStyleStart: '[dashboard] get management style start',
  getManagementStyleSuccess: '[dashboard] get management style success',
  getManagementStyleFailure: '[dashboard] get management style failure',

  getConflictnessStart: '[dashboard] get conflictness start',
  getConflictnessSuccess: '[dashboard] get conflictness success',
  getConflictnessFailure: '[dashboard] get conflictness failure',

  getTeamRolesStart: '[dashboard] get team roles start',
  getTeamRolesSuccess: '[dashboard] get team roles success',
  getTeamRolesFailure: '[dashboard] get team roles failure',
};

const mutations = {
  [mutationTypes.getDashboardByUserStart](state) {
    state.isLoading = true;
    state.metadata = null;
  },
  [mutationTypes.getDashboardByUserSuccess](state, payload) {
    state.isLoading = false;
    state.metadata = payload;
  },
  [mutationTypes.getDashboardByUserFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getDashboardByCompanyStart](state) {
    state.isLoading = true;
    state.metadata = null;
  },
  [mutationTypes.getDashboardByCompanySuccess](state, payload) {
    state.isLoading = false;
    state.metadata = payload;
  },
  [mutationTypes.getDashboardByCompanyFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.changeDashboardStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeDashboardSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeDashboardFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getUserStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getUserSuccess](state, payload) {
    state.isLoading = false;
    state.user = payload;
  },
  [mutationTypes.getUserFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getRoadmapsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getRoadmapsSuccess](state, payload) {
    state.isLoading = false;
    state.roadmaps = payload;
  },
  [mutationTypes.getRoadmapsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getRoadmapsSummaryStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getRoadmapsSummarySuccess](state, payload) {
    state.isLoading = false;
    state.roadmapsSummary = payload;
  },
  [mutationTypes.getRoadmapsSummaryFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getSpecsWithGrades360Start](state) {
    state.isLoading = true;
    state.specsWithGrades360 = null;
  },
  [mutationTypes.getSpecsWithGrades360Success](state, payload) {
    state.isLoading = false;
    state.specsWithGrades360 = payload;
  },
  [mutationTypes.getSpecsWithGrades360Failure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getHistoryGrades360Start](state) {
    state.isLoading = true;
    state.historyGrades360 = null;
  },
  [mutationTypes.getHistoryGrades360Success](state, payload) {
    state.isLoading = false;
    state.historyGrades360 = payload;
  },
  [mutationTypes.getHistoryGrades360Failure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getDiagram360BySpecStart](state) {
    state.isLoading = true;
    state.customBlocks.diagram360.data = null;
  },
  [mutationTypes.getDiagram360BySpecSuccess](state, payload) {
    state.isLoading = false;
    state.customBlocks.diagram360.data = payload;
  },
  [mutationTypes.getDiagram360BySpecFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getTestsStart](state) {
    state.isLoading = true;
    state.customBlocks.tests.data = null;
  },
  [mutationTypes.getTestsSuccess](state, payload) {
    state.isLoading = false;
    state.customBlocks.tests.data = payload;
  },
  [mutationTypes.getTestsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getTargetsStart](state) {
    state.isLoading = true;
    state.customBlocks.targets.data = null;
  },
  [mutationTypes.getTargetsSuccess](state, payload) {
    state.isLoading = false;
    state.customBlocks.targets.data = payload;
  },
  [mutationTypes.getTargetsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getPotentialStart](state) {
    state.isLoading = true;
    state.customBlocks.potential.data = null;
  },
  [mutationTypes.getPotentialSuccess](state, payload) {
    state.isLoading = false;
    state.customBlocks.potential.data = payload;
  },
  [mutationTypes.getPotentialFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getConflictnessStart](state) {
    state.isLoading = true;
    state.customBlocks.conflictness.data = null;
  },
  [mutationTypes.getConflictnessSuccess](state, payload) {
    state.isLoading = false;
    state.customBlocks.conflictness.data = payload;
  },
  [mutationTypes.getConflictnessFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getManagementStyleStart](state) {
    state.isLoading = true;
    state.customBlocks.managementStyle.data = null;
  },
  [mutationTypes.getManagementStyleSuccess](state, payload) {
    state.isLoading = false;
    state.customBlocks.managementStyle.data = payload;
  },
  [mutationTypes.getManagementStyleFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getTeamRolesStart](state) {
    state.isLoading = true;
    state.customBlocks.teamRoles.data = null;
  },
  [mutationTypes.getTeamRolesSuccess](state, payload) {
    state.isLoading = false;
    state.customBlocks.teamRoles.data = payload;
  },
  [mutationTypes.getTeamRolesFailure](state) {
    state.isLoading = false;
  },
};

export default mutations;
