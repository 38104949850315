import axios from 'axios';

const getSurveys = (
  companyId,
  surveyType = 'survey',
  isFeed = false,
  offset,
  limit,
  date_start,
  date_end,
  search = ''
) => {
  const params = isFeed
    ? {
        survey_type: surveyType,
        is_feed: isFeed,
        offset,
        limit,
        date_start,
        date_end,
        search,
      }
    : {
        survey_type: surveyType,
        is_feed: isFeed,
        offset,
        limit,
        search,
      };

  return axios.get(`/survey/company/${companyId}`, {params});
};

const getResearchments = (companyId, surveyType = 'survey', search = '', template = false) => {
  const params = {
    survey_type: surveyType,
    search,
  };

  if (template) params.template = template;

  return axios.get(`/survey/company/${companyId}/researchments`, {params});
};

const getUnattachedSurveys = (companyId, search = '', surveyType) => {
  const params = {
    survey_type: surveyType,
    search,
  };

  return axios.get(`/survey/company/${companyId}/researchments/surveys`, {params});
};

const getResearchmentTemplateList = (companyId) => {
  return axios.get(`/survey/company/${companyId}/researchments/template`);
};

const createResearchment = (companyId, data) =>
  axios.post(`/survey/company/${companyId}/researchments`, data);
const delResearchment = (companyId, researchmentId) =>
  axios.delete(`/survey/company/${companyId}/researchments/${researchmentId}`);
const saveResearchment = (companyId, researchmentId, data) =>
  axios.put(`/survey/company/${companyId}/researchments/${researchmentId}`, data);
const changeResearchmentStatus = (companyId, researchmentId, status) =>
  axios.post(`/survey/company/${companyId}/researchments/${researchmentId}/status`, {status});
const changeSurveyDates = (companyId, researchmentId, surveyId, data) =>
  axios.post(
    `/survey/company/${companyId}/researchments/${researchmentId}/surveys/${surveyId}`,
    data
  );
const rebuildResearchment = (companyId, researchmentId) =>
  axios.post(`/survey/company/${companyId}/researchments/${researchmentId}/rebuild`);
const startResearchmentCycle = (companyId, researchmentId) =>
  axios.post(`/survey/company/${companyId}/researchments/${researchmentId}/start-cycle`);
const assignResearchment = (companyId, researchmentId, data) =>
  axios.put(`/survey/company/${companyId}/researchments/${researchmentId}/assign`, data);
const getResearchmentAssignee = (companyId, researchmentId) =>
  axios.get(`/survey/company/${companyId}/researchments/${researchmentId}/assign`);

const createResearchmentTemplate = (companyId, researchmentId, data) =>
  axios.post(`/survey/company/${companyId}/researchments/${researchmentId}/template`, data);

const getSurveyStatistic = (companyId, survey_id) =>
  axios.get(`/survey/${survey_id}/company/${companyId}/statistic`);

const getFeedSettings = (companyId) => axios.get(`/survey/company/${companyId}/feed-settings`);

const getCompanySurveysStatistic = (companyId, args = {}) =>
  axios.get(`/survey/company/${companyId}/statistic`, {params: args});

const getRecipients = (companyId, survey_id) =>
  axios.get(`/survey/${survey_id}/company/${companyId}/recipients`);

const getSurvey = (companyId, survey_id, surveyType, cycle = undefined) =>
  axios.get(`/survey/${survey_id}/company/${companyId}${cycle ? '?cycle=' + cycle : ''}`);

const exportResearchment = (companyId, researchmentId, email) =>
  axios.get(
    `/survey/company/${companyId}/researchments/${researchmentId}/export?is_async=1${
      email ? '&email=' + email : ''
    }`
  );
const getSurveyPublic = (survey_id) => axios.get(`/survey/${survey_id}`);
const getSurveyMailing = (companyId, survey_id) =>
  axios.get(`/survey/${survey_id}/company/${companyId}/mailing`);
const setSurveyMailing = (companyId, survey_id, data) =>
  axios.put(`/survey/${survey_id}/company/${companyId}/mailing`, data);
const setSurveyResults = (survey_id, data) => axios.post(`/survey/${survey_id}/results`, data);
const createSurvey = (companyId, data, bind_to_series) => {
  const params = {};
  if (bind_to_series !== undefined) params.bind_to_series = bind_to_series;
  return axios.post(`/survey/company/${companyId}`, data, {params});
};
const createSurveyQuestion = (companyId, survey_id, data) =>
  axios.post(`/survey/${survey_id}/company/${companyId}/questions`, data);
const editSurveyQuestion = (companyId, survey_id, question_id, data) =>
  axios.put(`/survey/${survey_id}/company/${companyId}/questions/${question_id}`, data);
const delSurveyQuestion = (companyId, survey_id, question_id) =>
  axios.delete(`/survey/${survey_id}/company/${companyId}/questions/${question_id}`);
const delSurvey = (companyId, survey_id) =>
  axios.delete(`/survey/${survey_id}/company/${companyId}`);
const copySurveyQuestion = (companyId, survey_id, question_id) =>
  axios.post(`/survey/${survey_id}/company/${companyId}/questions/${question_id}`);
const getResearchment = (companyId, researchmentId) =>
  axios.get(`/survey/company/${companyId}/researchments/${researchmentId}`);
const getResearchmentSurveysByCycle = (companyId, researchmentId, cycle) =>
  axios.get(`/survey/company/${companyId}/researchments/${researchmentId}/cycle/${cycle}`);
const getSurveyResults = (companyId, survey_id) =>
  axios.get(`/survey/${survey_id}/company/${companyId}/results`);

const copySurvey = (companyId, survey_id, toCompanies = undefined) =>
  axios.get(`/survey/${survey_id}/company/${companyId}/copy`, {
    params: {to_companies: toCompanies},
  });

const copyResearchment = (companyId, researchmentId, data) =>
  axios.post(`/survey/company/${companyId}/researchments/${researchmentId}/copy`, data);

const changeSurvey = (companyId, survey_id, data) =>
  axios.put(`/survey/${survey_id}/company/${companyId}`, data);

const changeSurveyBlock = (companyId, survey_id, block, data) =>
  axios.put(`/survey/${survey_id}/company/${companyId}/${block}`, data);

const changeSurveyStatus = (companyId, survey_id, data) =>
  axios.post(`/survey/${survey_id}/company/${companyId}/status`, data);

const sendSurvey = (companyId, data) => axios.post(`/survey/company/${companyId}/send`, data);
const sendSurveyNow = (companyId, surveyId) =>
  axios.post(`/survey/${surveyId}/company/${companyId}/send-now`);
const setFeedSettings = (companyId, data) =>
  axios.post(`/survey/company/${companyId}/feed-settings`, data);

// timetable
const getTimetable = (companyId, survey_id) =>
  axios.get(`/survey/${survey_id}/company/${companyId}/timetable`);

const changeTimetable = (companyId, survey_id, data) =>
  axios.put(`/survey/${survey_id}/company/${companyId}/timetable`, data);

export default {
  getSurvey,
  exportResearchment,
  getSurveyPublic,
  getSurveyMailing,
  setSurveyMailing,
  setSurveyResults,
  createSurvey,
  createSurveyQuestion,
  editSurveyQuestion,
  delSurvey,
  delSurveyQuestion,
  copySurveyQuestion,
  getSurveys,
  getResearchment,
  getResearchmentSurveysByCycle,
  getUnattachedSurveys,
  getResearchments,
  getResearchmentTemplateList,
  createResearchmentTemplate,
  createResearchment,
  saveResearchment,
  changeResearchmentStatus,
  changeSurveyDates,
  assignResearchment,
  rebuildResearchment,
  startResearchmentCycle,
  delResearchment,
  copyResearchment,
  getSurveyResults,
  getSurveyStatistic,
  getFeedSettings,
  getCompanySurveysStatistic,
  getRecipients,
  copySurvey,
  changeSurvey,
  changeSurveyBlock,
  changeSurveyStatus,
  sendSurvey,
  sendSurveyNow,
  setFeedSettings,

  getResearchmentAssignee,

  getTimetable,
  changeTimetable,
};
