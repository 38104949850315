import Vue from 'vue';
import Vuex from 'vuex';

import settings from '@/store/modules/settings';
import directory from '@/store/modules/directory';
import auth from '@/store/modules/auth';
import dashboard from '@/store/modules/dashboard';
import user from '@/store/modules/user';
import company from '@/store/modules/company';
import team from '@/store/modules/team';
import survey from '@/store/modules/survey';
import adaptation from '@/store/modules/adaptation';
import license from '@/store/modules/license';
import external from '@/store/modules/external';
import interconnectivity from '@/store/modules/interconnectivity';
import testing from '@/store/modules/testing';
import reports from '@/store/modules/reports';
import roadmap from '@/store/modules/roadmap';
import roadmap360 from '@/store/modules/roadmap360';
import board from '@/store/modules/board';
import feedback from '@/store/modules/feedback';
import target from '@/store/modules/target';
import meeting from '@/store/modules/meeting';
import socket from '@/store/modules/socket';
import announcements from '@/store/modules/announcements';
import utils from '@/store/modules/utils';
import admin from '@/store/modules/admin';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    settings,
    directory,
    auth,
    dashboard,
    user,
    company,
    team,
    survey,
    adaptation,
    license,
    external,
    interconnectivity,
    testing,
    reports,
    roadmap,
    roadmap360,
    board,
    feedback,
    target,
    meeting,
    socket,
    announcements,
    utils,
    admin,
  },
  state: {
    _socket: {
      isConnected: false,
      message: '',
      reconnectError: false,
    },
  },
  mutations: {
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state._socket.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      state._socket.isConnected = false;
      //console.log(event);
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event);
    },
    SOCKET_ONMESSAGE(state, message) {
      state._socket.message = message;
    },
    SOCKET_RECONNECT(state, count) {
      console.info(state, count);
    },
    SOCKET_RECONNECT_ERROR(state) {
      state._socket.reconnectError = true;
    },
  },
});
