export const mutationTypes = {
  getPlansStart: '[adaptation] get plans start',
  getPlansSuccess: '[adaptation] get plans success',
  getPlansFailure: '[adaptation] get plans failure',

  getAssignedAdaptationsStart: '[adaptation] get assigned adaptations start',
  getAssignedAdaptationsSuccess: '[adaptation] get assigned adaptations success',
  getAssignedAdaptationsFailure: '[adaptation] get assigned adaptations failure',

  getUserAdaptationStart: '[adaptation] get user adaptation start',
  getUserAdaptationSuccess: '[adaptation] get user adaptation success',
  getUserAdaptationFailure: '[adaptation] get user adaptation failure',

  assignAdaptationStart: '[adaptation] assign adaptation start',
  assignAdaptationSuccess: '[adaptation] assign adaptation success',
  assignAdaptationFailure: '[adaptation] assign adaptation failure',

  createPlanStart: '[adaptation] create a plan start',
  createPlanSuccess: '[adaptation] create a plan success',
  createPlanFailure: '[adaptation] create a plan failure',

  deletePlanStart: '[adaptation] delete the plan start',
  deletePlanSuccess: '[adaptation] delete the plan success',
  deletePlanFailure: '[adaptation] delete the plan failure',

  exportPlanStart: '[adaptation] export the plan start',
  exportPlanSuccess: '[adaptation] export the plan success',
  exportPlanFailure: '[adaptation] export the plan failure',

  getTasksStart: '[adaptation] get tasks start',
  getTasksSuccess: '[adaptation] get tasks success',
  getTasksFailure: '[adaptation] get tasks failure',

  createTaskStart: '[adaptation] create a task start',
  createTaskSuccess: '[adaptation] create a task success',
  createTaskFailure: '[adaptation] create a task failure',

  editTaskStart: '[adaptation] change the task start',
  editTaskSuccess: '[adaptation] change the task success',
  editTaskFailure: '[adaptation] change the task failure',

  swapTasksStart: '[adaptation] swap tasks start',
  swapTasksSuccess: '[adaptation] swap tasks success',
  swapTasksFailure: '[adaptation] swap tasks failure',

  deleteTaskImageStart: '[adaptation] delete the task image start',
  deleteTaskImageSuccess: '[adaptation] delete the task image success',
  deleteTaskImageFailure: '[adaptation] delete the task image failure',

  deleteTaskFileStart: '[adaptation] delete the task file start',
  deleteTaskFileSuccess: '[adaptation] delete the task file success',
  deleteTaskFileFailure: '[adaptation] delete the task file failure',

  deleteTaskStart: '[adaptation] delete the task start',
  deleteTaskSuccess: '[adaptation] delete the task success',
  deleteTaskFailure: '[adaptation] delete the task failure',
};

const mutations = {
  [mutationTypes.getPlansStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getPlansSuccess](state, payload) {
    state.isLoading = false;
    state.plans = payload;
  },
  [mutationTypes.getPlansFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getAssignedAdaptationsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getAssignedAdaptationsSuccess](state, payload) {
    state.isLoading = false;
    state.assignedAdaptations = payload;
  },
  [mutationTypes.getAssignedAdaptationsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getUserAdaptationStart](state) {
    state.isLoading = true;
    state.userAdaptation = null;
  },
  [mutationTypes.getUserAdaptationSuccess](state, payload) {
    state.isLoading = false;
    state.userAdaptation = payload;
  },
  [mutationTypes.getUserAdaptationFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.assignAdaptationStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.assignAdaptationSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.assignAdaptationFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createPlanStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createPlanSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createPlanFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deletePlanStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deletePlanSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deletePlanFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.exportPlanStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.exportPlanSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.exportPlanFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getTasksStart](state) {
    state.isLoading = true;
    state.tasks = null;
  },
  [mutationTypes.getTasksSuccess](state, payload) {
    state.isLoading = false;
    state.tasks = payload;
  },
  [mutationTypes.getTasksFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createTaskStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createTaskSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createTaskFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.editTaskStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.editTaskSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.editTaskFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.swapTasksStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.swapTasksSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.swapTasksFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteTaskImageStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteTaskImageSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteTaskImageFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteTaskFileStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteTaskFileSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteTaskFileFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteTaskStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteTaskSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteTaskFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },
};

export default mutations;
