import SEO from '@/helpers/seo';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import vuetify from '@/plugins/vuetify';
import '@/plugins/apexcharts';
import '@/plugins/echarts';
import '@/plugins/chartjs';
import '@/plugins/vue-shortkey';
import '@/plugins/dompurify-html';
import i18n from '@/locale/i18n';
import VueNativeSock from 'vue-native-websocket';

import {actionsTypes as settingsActionsTypes} from '@/store/modules/settings/actions';
import {errorAlert} from '@/helpers/utils';
import '@/helpers/global';
import '@/sass/main.scss';

import './globalComponents';
import {allowedUnAuthNames, routeNames} from '@/router/names';
import AuthModuleLoginForm from '@bbd/auth-vue';

Vue.prototype.$routeNames = routeNames;

Vue.use(AuthModuleLoginForm, {
  router,
  store,
  locale: i18n,
  hostRouting: true,
  pathPrefix: '',
  allowUnApprovedEmail: false,
  registrationDisabled: true,
  needUserRole: false,
  privacy: {link: 'https://beehive.bobdaytech.ru/resources/shared/PDA.pdf', name: 'Beehive'},
  supportMail: 'support@beehive.team',
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const {response} = error;
    const {errorMessages} = i18n.messages[i18n.locale];

    if (store.state.auth.isLoggedIn === true) {
      switch (response.status) {
        case 401:
          window.location.href = `${window.location.origin}/#/authentication/login&redirectedFrom=${window.location.href}`;
          break;

        case 403:
          if (!allowedUnAuthNames.includes(router.currentRoute.name))
            errorAlert(`ERROR: ${response.status} ${errorMessages.permissionDenied}`);
          break;

        case 409:
          errorAlert(response.data.message);
          break;

        default:
          errorAlert(`ERROR: ${response.status} ${response.data.message}`);
          break;
      }
    }
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;

async function createApp() {
  const requiredPromises = [store.dispatch(settingsActionsTypes.getSEOParams)];
  await Promise.all(requiredPromises);
  SEO.initMonitoring(store.state.settings.SEOParams);
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
}

// Мориарти проверяем до всего остального
store
  .dispatch(settingsActionsTypes.getWebsocketUrl)
  .then(() => {
    console.log('Moriarty enabled');
    Vue.use(VueNativeSock, store.state.settings.websocketUrl, {
      store,
      format: 'json',
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 3000,
    });
    createApp();
  })
  .catch(() => {
    console.log('Moriarty disabled');
    createApp();
  });
