import {gettersTypes as companyGettersTypes} from '@/store/modules/company/getters';

export const gettersTypes = {
  isAdmin: '[user] isAdmin',
  isOrganizer: '[user] isOrganizer',
  companyId: '[user] current company id',
  selfTests360: '[user] self tests 360',
  notSelfTests360: '[user] not self tests 360',
  numOfSelfTests360: '[user] number of self tests360',
  numOfNotSelfTests360: '[user] number of not self tests360',
  numOfTests360ForApproval: '[user] number of tests360 for approval',
  accessRights: '[user] access rights',
};

const getters = {
  [gettersTypes.isAdmin]: (state) => {
    if (!state.currentUser) return false;
    return state.currentUser.roles && state.currentUser.roles.indexOf('admin') > -1;
  },
  [gettersTypes.isOrganizer]: (state, getters, rootState) => {
    if (!state.companies) return false;
    return state.companies.length && rootState.settings.instance.admin_allow_company_orgs;
  },
  [gettersTypes.companyId]: (state) => {
    if (!state.currentUser) return 0;
    return state.currentUser.company_id || 0;
  },
  [gettersTypes.selfTests360]: (state) => {
    if (!state.tests360) return null;
    return state.tests360.filter((test) => test.is_selftest);
  },
  [gettersTypes.notSelfTests360]: (state) => {
    if (!state.tests360) return null;
    return state.tests360.filter((test) => !test.is_selftest);
  },
  [gettersTypes.numOfSelfTests360]: (state, getters) => {
    if (!getters[gettersTypes.selfTests360]) return null;
    return getters[gettersTypes.selfTests360].length;
  },
  [gettersTypes.numOfNotSelfTests360]: (state, getters) => {
    if (!getters[gettersTypes.notSelfTests360]) return null;
    return getters[gettersTypes.notSelfTests360].length;
  },
  [gettersTypes.numOfTests360ForApproval]: (state) => {
    if (!state.tests360ForApproval) return null;
    return state.tests360ForApproval.length;
  },
  [gettersTypes.accessRights]: (state, getters) => {
    return getters[companyGettersTypes.isCurrentCompany]
      ? state.currentAccessRights
      : state.accessRights;
  },
};

export default getters;
