import userApi from '@/api/user';
import {mutationTypes} from '@/store/modules/user/mutations';

export const actionsTypes = {
  getUsers: '[user] get users',
  getCurrentUser: '[user] get current user',
  getMetadataUser: '[user] get metadata',
  getCurrentAccessRights: '[user] get current access rights',
  getAccessRights: '[user] get access rights',
  getSpecsWithGrades: '[user] get specializations with grades',

  changeMetadataUser: '[user] change metadata',
  changeAvatar: '[user] change avatar',
  changeEmail: '[user] change email',
  approveEmail: '[user] approve email',
  changeSpecializations: '[user] change specializations',

  changeHRInfo: '[user] change HR information',
  createHRAttachment: '[user] create HR attachment',
  deleteHRAttachment: '[user] delete HR attachment',

  getEmployers: '[user] get employers',
  getCompanies: '[user] get companies',

  createCompany: '[user] create company',
  createCompanyLogo: '[user] create company logo',

  getPortfolio: '[user] get portfolio',
  createPortfolio: '[user] create portfolio',
  deletePortfolio: '[user] delete portfolio',

  getWorkExp: '[user] get work experience',
  createWorkExp: '[user] create work experience',
  changeWorkExp: '[user] change work experience',
  deleteWorkExp: '[user] delete work experience',

  getEducation: '[user] get education',
  changeEducation: '[user] change education',
  deleteEducation: '[user] delete education',

  getReports: '[user] get reports',

  getTests360: '[user] get tests 360',
  getTests360ForApproval: '[user] get tests 360 for approval',

  acceptInvitation: '[user] accept invitation',
  rejectInvitation: '[user] reject invitation',

  getNotifications: '[user] get notifications',
  changeNotification: '[user] change notification',
  deleteNotification: '[user] delete notification',

  getSelfTargets: '[user] get self targets',
  getCuratorTargets: '[user] get curator targets',
  exportCuratorTargets: '[user] export curator targets',

  getCuratorAdaptation: '[user] get curator adaptation',
};

const actions = {
  [actionsTypes.getUsers](context, {fio, page, limit, start}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUsersStart);
      userApi
        .getUsers(fio, page, limit, start)
        .then((response) => {
          context.commit(mutationTypes.getUsersSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUsersFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getCurrentUser](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCurrentUserStart);
      userApi
        .getMetadataUser(id)
        .then((response) => {
          context.commit(mutationTypes.getCurrentUserSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCurrentUserFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getMetadataUser](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMetadataUserStart);
      userApi
        .getMetadataUser(id)
        .then((response) => {
          context.commit(mutationTypes.getMetadataUserSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMetadataUserFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getSpecsWithGrades](context, {id, teamId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSpecsWithGradesStart);
      userApi
        .getSpecsWithGrades(id, teamId)
        .then((response) => {
          context.commit(mutationTypes.getSpecsWithGradesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSpecsWithGradesFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getCurrentAccessRights](context, companyId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCurrentAccessRightsStart);
      userApi
        .getAccessRights(companyId)
        .then((response) => {
          context.commit(mutationTypes.getCurrentAccessRightsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCurrentAccessRightsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getAccessRights](context, companyId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getAccessRightsStart);
      userApi
        .getAccessRights(companyId)
        .then((response) => {
          context.commit(mutationTypes.getAccessRightsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getAccessRightsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.changeMetadataUser](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeMetadataUserStart);
      userApi
        .changeMetadataUser(credentials)
        .then(() => {
          context.commit(mutationTypes.changeMetadataUserSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.changeMetadataUserFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeAvatar](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeAvatarStart);
      userApi
        .changeAvatar(credentials)
        .then((response) => {
          context.commit(mutationTypes.changeAvatarSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeAvatarFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeEmail](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeEmailStart);
      userApi
        .changeEmail(credentials)
        .then(() => {
          context.commit(mutationTypes.changeEmailSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.changeEmailFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.approveEmail](context, code) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeEmailStart);
      userApi
        .approveEmail(code)
        .then(() => {
          context.commit(mutationTypes.changeEmailSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.changeEmailFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeSpecializations](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeSpecializationsStart);
      userApi
        .changeSpecializations(credentials)
        .then(() => {
          context.commit(mutationTypes.changeSpecializationsSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.changeSpecializationsFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.changeHRInfo](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeHRInfoStart);
      userApi
        .changeHRInfo(id, data)
        .then(() => {
          context.commit(mutationTypes.changeHRInfoSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.changeHRInfoFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.createHRAttachment](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createHRAttachmentStart);
      userApi
        .createHRAttachment(id, data)
        .then((response) => {
          context.commit(mutationTypes.createHRAttachmentSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createHRAttachmentFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteHRAttachment](context, {id, attachId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteHRAttachmentStart);
      userApi
        .deleteHRAttachment(id, attachId)
        .then((response) => {
          context.commit(mutationTypes.deleteHRAttachmentSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteHRAttachmentFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getEmployers](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getEmployersStart);
      userApi
        .getEmployers(id)
        .then((response) => {
          context.commit(mutationTypes.getEmployersSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getEmployersFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getCompanies](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCompaniesStart);
      userApi
        .getCompanies(id)
        .then((response) => {
          context.commit(mutationTypes.getCompaniesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCompaniesFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.createCompany](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createCompanyStart);
      userApi
        .createCompany(id, data)
        .then(() => {
          context.commit(mutationTypes.createCompanySuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.createCompanyFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.createCompanyLogo](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createCompanyLogoStart);
      userApi
        .createCompanyLogo(id, data)
        .then((response) => {
          context.commit(mutationTypes.createCompanyLogoSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createCompanyLogoFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getPortfolio](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getPortfolioStart);
      userApi
        .getPortfolio(id)
        .then((response) => {
          context.commit(mutationTypes.getPortfolioSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getPortfolioFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.createPortfolio](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createPortfolioStart);
      userApi
        .createPortfolio(id, data)
        .then((response) => {
          context.commit(mutationTypes.createPortfolioSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createPortfolioFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deletePortfolio](context, {id, portfolioId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deletePortfolioStart);
      userApi
        .deletePortfolio(id, portfolioId)
        .then((response) => {
          context.commit(mutationTypes.deletePortfolioSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deletePortfolioFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getWorkExp](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getWorkExpStart);
      userApi
        .getWorkExp(id)
        .then((response) => {
          context.commit(mutationTypes.getWorkExpSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getWorkExpFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.createWorkExp](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createWorkExpStart);
      userApi
        .createWorkExp(id, data)
        .then((response) => {
          context.commit(mutationTypes.createWorkExpSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createWorkExpFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeWorkExp](context, {id, experienceId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeWorkExpStart);
      userApi
        .changeWorkExp(id, experienceId, data)
        .then((response) => {
          context.commit(mutationTypes.changeWorkExpSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeWorkExpFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteWorkExp](context, {id, experienceId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteWorkExpStart);
      userApi
        .deleteWorkExp(id, experienceId)
        .then((response) => {
          context.commit(mutationTypes.deleteWorkExpSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteWorkExpFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getEducation](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getEducationStart);
      userApi
        .getEducation(id)
        .then((response) => {
          context.commit(mutationTypes.getEducationSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getEducationFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.changeEducation](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeEducationStart);
      userApi
        .changeEducation(id, data)
        .then((response) => {
          context.commit(mutationTypes.changeEducationSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeEducationFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteEducation](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteEducationStart);
      userApi
        .deleteEducation(id, data)
        .then((response) => {
          context.commit(mutationTypes.deleteEducationSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteEducationFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getReports](context, {id, companyId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getReportsStart);
      userApi
        .getReports(id, companyId)
        .then((response) => {
          context.commit(mutationTypes.getReportsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getReportsFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getTests360](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTests360Start);
      userApi
        .getTests360()
        .then((response) => {
          context.commit(mutationTypes.getTests360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTests360Failure);
          reject(result);
        });
    });
  },
  [actionsTypes.getTests360ForApproval](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTests360ForApprovalStart);
      userApi
        .getTests360ForApproval()
        .then((response) => {
          context.commit(mutationTypes.getTests360ForApprovalSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTests360ForApprovalFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.acceptInvitation](context, {id, employeeId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.acceptInvitationStart);
      userApi
        .acceptInvitation(id, employeeId)
        .then((response) => {
          context.commit(mutationTypes.acceptInvitationSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.acceptInvitationFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.rejectInvitation](context, {id, employeeId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.rejectInvitationStart);
      userApi
        .rejectInvitation(id, employeeId)
        .then((response) => {
          context.commit(mutationTypes.rejectInvitationSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.rejectInvitationFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getNotifications](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getNotificationsStart);
      userApi
        .getNotifications()
        .then((response) => {
          context.commit(mutationTypes.getNotificationsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getNotificationsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.changeNotification](context, {notificationId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeNotificationStart);
      userApi
        .changeNotification(notificationId)
        .then((response) => {
          context.commit(mutationTypes.changeNotificationSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeNotificationFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteNotification](context, {notificationId, isRead}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteNotificationStart);
      userApi
        .deleteNotification(notificationId, isRead)
        .then((response) => {
          context.commit(mutationTypes.deleteNotificationSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteNotificationFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getSelfTargets](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSelfTargetsStart);
      userApi
        .getSelfTargets()
        .then((response) => {
          context.commit(mutationTypes.getSelfTargetsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSelfTargetsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getCuratorTargets](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCuratorTargetsStart);
      userApi
        .getCuratorTargets()
        .then((response) => {
          context.commit(mutationTypes.getCuratorTargetsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCuratorTargetsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.exportCuratorTargets](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.exportCuratorTargetsStart);
      userApi
        .exportCuratorTargets()
        .then((response) => {
          context.commit(mutationTypes.exportCuratorTargetsSuccess);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.exportCuratorTargetsFailure, result.response.status);
          reject(result);
        });
    });
  },

  [actionsTypes.getCuratorAdaptation](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCuratorAdaptationStart);
      userApi
        .getCuratorAdaptation()
        .then((response) => {
          context.commit(mutationTypes.getCuratorAdaptationSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCuratorAdaptationFailure);
          reject(result);
        });
    });
  },
};

export default actions;
