import adaptationApi from '@/api/adaptation';
import {mutationTypes} from '@/store/modules/adaptation/mutations';

export const actionsTypes = {
  getPlans: '[adaptation] get plans',
  getAssignedAdaptations: '[adaptation] get assigned adaptations',
  getUserAdaptation: '[adaptation] get user adaptation',

  assignAdaptation: '[adaptation] assign adaptations',
  createPlan: '[adaptation] create a plan',
  deletePlan: '[adaptation] delete the plan',
  exportPlan: '[adaptation] export the plan',

  getTasks: '[adaptation] get tasks',
  createTask: '[adaptation] create a task',
  editTask: '[adaptation] change the task',
  swapTasks: '[adaptation] swap tasks',
  deleteTaskImage: '[adaptation] delete the task image',
  deleteTaskFile: '[adaptation] delete the task file',
  deleteTask: '[adaptation] delete the task',
};

const actions = {
  [actionsTypes.getPlans](context, companyId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getPlansStart);
      adaptationApi
        .getPlans(companyId)
        .then((response) => {
          context.commit(mutationTypes.getPlansSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getPlansFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getAssignedAdaptations](context, companyId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getAssignedAdaptationsStart);
      adaptationApi
        .getAssignedAdaptations(companyId)
        .then((response) => {
          context.commit(mutationTypes.getAssignedAdaptationsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getAssignedAdaptationsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getUserAdaptation](context, {companyId, adaptationId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUserAdaptationStart);
      adaptationApi
        .getUserAdaptation(companyId, adaptationId)
        .then((response) => {
          context.commit(mutationTypes.getUserAdaptationSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUserAdaptationFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.assignAdaptation](context, {companyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.assignAdaptationStart);
      adaptationApi
        .assignAdaptation(companyId, data)
        .then((response) => {
          context.commit(mutationTypes.assignAdaptationSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.assignAdaptationFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.createPlan](context, {companyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createPlanStart);
      adaptationApi
        .createPlan(companyId, data)
        .then((response) => {
          context.commit(mutationTypes.createPlanSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createPlanFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deletePlan](context, {companyId, planId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deletePlanStart);
      adaptationApi
        .deletePlan(companyId, planId)
        .then((response) => {
          context.commit(mutationTypes.deletePlanSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deletePlanFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.exportPlan](context, {companyId, planId, format}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.exportPlanStart);
      adaptationApi
        .exportPlan(companyId, planId, format)
        .then((response) => {
          context.commit(mutationTypes.exportPlanSuccess);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.exportPlanFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getTasks](context, {companyId, planId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTasksStart);
      adaptationApi
        .getTasks(companyId, planId)
        .then((response) => {
          context.commit(mutationTypes.getTasksSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTasksFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.createTask](context, {companyId, planId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createTaskStart);
      adaptationApi
        .createTask(companyId, planId, data)
        .then((response) => {
          context.commit(mutationTypes.createTaskSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createTaskFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.editTask](context, {companyId, planId, taskId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.editTaskStart);
      adaptationApi
        .editTask(companyId, planId, taskId, data)
        .then((response) => {
          context.commit(mutationTypes.editTaskSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.editTaskFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.swapTasks](context, {companyId, planId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.swapTasksStart);
      adaptationApi
        .swapTasks(companyId, planId, data)
        .then((response) => {
          context.commit(mutationTypes.swapTasksSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.swapTasksFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteTaskImage](context, {companyId, taskId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteTaskImageStart);
      adaptationApi
        .deleteTaskImage(companyId, taskId)
        .then((response) => {
          context.commit(mutationTypes.deleteTaskImageSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteTaskImageFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteTaskFile](context, {companyId, taskId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteTaskFileStart);
      adaptationApi
        .deleteTaskFile(companyId, taskId)
        .then((response) => {
          context.commit(mutationTypes.deleteTaskFileSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteTaskFileFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteTask](context, {companyId, planId, taskId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteTaskStart);
      adaptationApi
        .deleteTask(companyId, planId, taskId)
        .then((response) => {
          context.commit(mutationTypes.deleteTaskSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteTaskFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default actions;
