import {mapState} from 'vuex';
import variables from '@/helpers/variables';
import {isObject, isFileExists} from '@/helpers/utils';

export const themeMixin = {
  data() {
    return {
      v: variables,
    };
  },
  computed: {
    ...mapState({
      instance: (state) => state.settings.instance,
    }),
    customFont() {
      if (!this.instance) return null;
      return this.instance.body_font_family;
    },
    customTheme() {
      if (!this.instance) return null;
      return this.instance.color_theme;
    },
    isDarkTheme() {
      return this.$vuetify.theme.dark;
    },
    activeTheme() {
      return this.isDarkTheme ? 'dark' : 'light';
    },
    colors() {
      return this.$vuetify.theme.themes[this.activeTheme];
    },
  },
  methods: {
    addClassActiveTheme() {
      const html = document.querySelector('html');
      if (html) html.classList.add(`theme--${this.activeTheme}`);
    },
    setCustomFont() {
      if (this.customFont) {
        try {
          const fontName = this.customFont.replace(/ /g, '');
          const url = require(`@/assets/fonts/${fontName}/${fontName}Regular/${fontName}Regular.woff2`);

          if (isFileExists(url)) {
            const appContainer = document.querySelector('#app');
            if (appContainer) {
              appContainer.classList.add('custom-font');
              appContainer.style.fontFamily = `'${this.customFont}', sans-serif`;
            }
          }
        } catch (e) {
          return console.error(`Font "${this.customFont}" is undefined`, e);
        }
      }
    },
    setCustomTheme() {
      if (this.customTheme) {
        for (let theme in this.customTheme) {
          if (Object.prototype.hasOwnProperty.call(this.customTheme, theme)) {
            if (isObject(this.customTheme[theme])) {
              for (let color in this.customTheme[theme]) {
                if (Object.prototype.hasOwnProperty.call(this.customTheme[theme], color)) {
                  this.$vuetify.theme.themes[theme][color] = this.customTheme[theme][color];
                }
              }
            }
          }
        }
      }
    },
  },
};
